import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    membershipBadges: [],
    contributorClaimedDataBackend: null,
    membershipVoucher: null,
    membershipBadgesForAddress: [],
    membershipBadgeClaimed: null,
    claimMembershipLoading: false,
    claimTakingTime: null,
    disableClaimBtn: false,
    showMetamaskSignText: false,
    txHashFetched: false,
    selectedMember: null,
    communityMembers: [],
    selectedNav: "badges",
    showMembershipChangeModal: false,
    membershipCreateLoading: false,
    showMembershipCreateModal: false,
    showMembershipMintingModal: false,
    allDaoMembers: null,
    unclaimedMembershipBadges: [],
    unclaimedMembershipVouchersForAddress: [],
    selectedMemberPastContributions: [],
    selectedMemberParticipationBadges: [],
    showCustomBadgeCreateModal: false,
    showCustomBadgeMintModal: false,
    customBadges: [],
    discordServers: [],
    showMembershipBadgeClaimed: false,
    showDiscordEnablePopup: false,
    showDiscordSetupModal: false,
    setEditDiscordSetupModal: false,
    discordRoles: [],
    discordMembershipRole: [],
    guildId: false,
    discordSetup: false,
    isParticipationBadgeActive: false,
    isEditingParticipationBadge: false,
    participationBadgeData: {
        minimumParticipationPercentage: 60,
        executingRoles: [],
        directMint: false,
    },
    showParticipationBadgeModal: false,
    showParticipationDiscordSetupModal: false,
    isDiscordConnected: false,
    showCultMintingStep: false,
    showMembershipUpdatedModal: false,
}

const membershipSlice = createSlice({
    name: "membership",
    initialState,
    reducers: {
        setMembershipBadges(state, action) {
            state.membershipBadges = action.payload.membershipBadges
            state.discordSetup = action.payload.discord_roles
        },
        set_membership_role(state, action) {
            state.discordMembershipRole = action.payload.updatedMembership
        },
        set_guild_id(state, action) {
            state.guildId = action.payload.guildId
        },
        setMembershipUnclaimed(state, action) {
            state.unclaimedMembershipBadges = action.payload.unclaimedMembership
        },
        setClaimedDataFromBE(state, action) {
            state.contributorClaimedDataBackend =
                action.payload.contributorClaimedDataBackend
        },
        setDaoMembers(state, action) {
            state.allDaoMembers = action.payload.allDaoMembers
        },
        setMembershipVoucher(state, action) {
            state.membershipVoucher = action.payload.membershipVoucher
        },
        setDiscordServers(state, action) {
            state.discordServers = action.payload.servers
        },
        setMembershipBadgesForAddress(state, action) {
            state.membershipBadgesForAddress =
                action.payload.membershipBadgesForAddress
        },
        setMembershipBadgeClaimed(state, action) {
            state.membershipBadgeClaimed = action.payload.membershipBadgeClaimed
        },
        setClaimMembershipLoading(state, action) {
            state.claimMembershipLoading = action.payload.claimMembershipLoading
        },
        setClaimTakingTime(state, action) {
            state.claimTakingTime = action.payload.claimTakingTime
        },
        setDisableClaimBtn(state, action) {
            state.disableClaimBtn = action.payload.disableClaimBtn
        },
        setShowMetamaskSignText(state, action) {
            state.showMetamaskSignText = action.payload.showMetamaskSignText
        },
        setTxHashFetched(state, action) {
            state.txHashFetched = action.payload.txHashFetched
        },
        setSelectedMember(state, action) {
            state.selectedMember = action.payload.selectedMember
        },
        setCommunityMembers(state, action) {
            state.communityMembers = action.payload.communityMembers
        },
        setSelectedNav(state, action) {
            state.selectedNav = action.payload.selectedNav
        },
        setShowMembershipChangeModal(state, action) {
            state.showMembershipChangeModal =
                action.payload.showMembershipChangeModal
        },
        setMembershipCreateLoading(state, action) {
            state.membershipCreateLoading =
                action.payload.membershipCreateLoading
        },
        setShowMembershipCreateModal(state, action) {
            state.showMembershipCreateModal =
                action.payload.showMembershipCreateModal
        },
        setShowDiscordSetupModal(state, action) {
            state.showDiscordSetupModal = action.payload.status
        },
        setShowDiscordSetupEditModal(state, action) {
            state.showDiscordSetupModal = action.payload.status
            state.setEditDiscordSetupModal = action.payload.edit
        },
        setShowDiscordPopup(state, action) {
            state.showDiscordEnablePopup = action.payload.status
        },
        setShowMembershipMintingModal(state, action) {
            state.showMembershipMintingModal =
                action.payload.showMembershipMintingModal
        },
        setUnclaimedMembershipVouchersForAddress(state, action) {
            state.unclaimedMembershipVouchersForAddress =
                action.payload.unclaimedMembershipVouchersForAddress
        },
        setSelectedMemberPastContributions(state, action) {
            state.selectedMemberPastContributions =
                action.payload.selectedMemberPastContributions
        },
        setShowCustomBadgeCreateModal(state, action) {
            state.showCustomBadgeCreateModal =
                action.payload.showCustomBadgeCreateModal
        },
        setShowCustomBadgeMintModal(state, action) {
            state.showCustomBadgeMintModal =
                action.payload.showCustomBadgeMintModal
        },
        setCustomBadges(state, action) {
            state.customBadges = action.payload.customBadges
        },
        setShowMembershipBadgeClaimed(state, action) {
            state.showMembershipBadgeClaimed =
                action.payload.showMembershipBadgeClaimed
        },
        setDiscordRoles(state, action) {
            state.discordRoles = action.payload.roles
        },
        // discordDisconnectModal(state, action) {
        //     state.discordDisconnectModal = action.payload.status
        // },
        setParticipationBadge(state, action) {
            state.isParticipationBadgeActive =
                action.payload.participation_badge_active
            state.participationBadgeData = {
                minimumParticipationPercentage:
                    action.payload.minimumParticipationPercentage,
                executingRoles: action.payload.executingRoles,
                directMint: action.payload.directMint,
            }
        },
        setShowParticipationBadgeModal(state, action) {
            state.showParticipationBadgeModal =
                action.payload.showParticipationBadgeModal
        },
        setIsEditingParticipationBadge(state, action) {
            state.isEditingParticipationBadge =
                action.payload.isEditingParticipationBadge
        },
        setShowParticipationDiscordSetupModal(state, action) {
            state.showParticipationDiscordSetupModal = action.payload.status
        },
        setIsDiscordConnected(state, action) {
            state.isDiscordConnected = action.payload.status
        },
        setShowCultMintingStep(state, action) {
            state.showCultMintingStep = action.payload.showCultMintingStep
        },
        resetState() {
            return initialState
        },
        setShowMembershipUpdatedModal(state, action) {
            state.showMembershipUpdatedModal =
                action.payload.showMembershipUpdatedModal
        },
        setSelectedMemberParticipationBadges(state, action) {
            state.selectedMemberParticipationBadges =
                action.payload.selectedMemberParticipationBadges
        },
    },
})

export const membershipAction = membershipSlice.actions

export default membershipSlice

const routes = {
    auth: {
        getNonce: "/auth/nonce",
        getSignature: "/auth/signature",
    },
    dao: {
        approvers: "/dao/approvers",
        discord: "/dao/discord",
        getCommunityRole: "/dao/community-roles",
        getDao: "/dao/",
        getDaoMembership: "/dao/memberships",
        getOurSafes: "/dao/our-safes",
        getRole: "/dao/my-role",
        joinContributor: "/dao/join",
        registerDao: "/dao/register",
        updateDao: "/dao",
        updateDaoMember: "/dao/update/dao_member",
        updateUser: "/dao/update/user",
        removeBot: "/dao/remove/bot",
    },
    contribution: {
        createContri: "/contrib",
        createSchema: "/contrib/add_contrib_schema",
        execute: "/contrib/payout/execute",
        externalPayout: "/contrib/external_payout",
        pastContributions: "/contrib/past_contribs",
        payout: "/contrib/payout",
        reject: "/contrib/payout/reject",
        signPayout: "/contrib/payout/sign",
        updatePayout: "/contrib/payout/update",
        markAsSeen: "/contrib/mark_badge_seen",
    },
    pocp: {
        collectibleInfo: "/eth/collectible",
        relay: "/eth/relay",
    },
    discord: {
        discordInfo: "/discord",
        guildRoles: "/discord/guild_roles",
        identifierStatus: "/discord/identifier/status",
        memberDiscord: "/discord/member_discord",
        memberGuilds: "/discord/member_guilds",
        register: "/discord/register",
        removeBot: "/discord/remove_bot",
        toggleBot: "/discord/toggle_active",
        userId: "/discord/user_id",
        toggle: "/discord/toggle",
    },
    membership: {
        createMembershipBadges: "/membership",
        createMembershipVoucher: "/membership/voucher",
        getCommunityMembers: "/community",
        getMembershipBadgesList: "/membership",
        getMembershipVoucher: "/membership/voucher",
        refreshDiscordMembership: "/membership/membership_using_discord",
        unclaimedVouchers: "/membership/non_member_unclaimed_vouchers",
    },
    arweave: {
        membership: "/membership",
    },
    customBadge: {
        createBadge: "/badge/create_badge_type",
        createBadgeVoucher: "/badge/create_badge_voucher",
        getCustomBadges: "/badge/badge_types",
        pastBadges: "/badge/past_badges",
        updateBadge: "/badge/update_badge_type",
    },
    strategy: {
        strategyExecution: "/strategy/executions",
        triggerStrategy: "/strategy/trigger",
    },
}

export default routes

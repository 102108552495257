import "./App.scss"

import * as dayjs from "dayjs"
import * as relativeTimePlugin from "dayjs/plugin/relativeTime"
import * as utc from "dayjs/plugin/utc"
import React, { lazy, Suspense, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useSigner } from "wagmi"

import ErrorBoundary from "./components/ErrorBoundary"
import { signout } from "./store/actions/auth-action"
const CultDao = lazy(() => import("./daos/cult"))
const MainTool = lazy(() => import("./main"))

function App() {
    dayjs.extend(relativeTimePlugin)
    dayjs.extend(utc)
    const dispatch = useDispatch()

    const [redirected, setRedirected] = useState(false)

    const { data: signer } = useSigner()
    const listenersSet = useRef(null)

    if (signer?.provider?.provider && !listenersSet.current) {
        signer.provider?.provider?.on("accountsChanged", () => {
            dispatch(signout())
            if (window.location.pathname.startsWith("/contributor/invite/")) {
                window.location.reload()
            } else {
                window.location.replace(window.location.origin)
            }
        })
        signer.provider?.provider?.on("chainChanged", () => {
            dispatch(signout())
            if (window.location.pathname.startsWith("/contributor/invite/")) {
                window.location.reload()
            } else {
                window.location.replace(window.location.origin)
            }
        })
        signer.provider?.provider?.on("disconnect", () => {
            dispatch(signout())
            if (window.location.pathname.startsWith("/contributor/invite/")) {
                window.location.reload()
            } else {
                window.location.replace(window.location.origin)
            }
        })
        listenersSet.current = true
    }

    if (window.location.hostname === "pony.rep3.gg" && !redirected) {
        if (window.location.pathname) {
            window.open(
                `https://app.rep3.gg${window.location.pathname}`,
                "_self"
            )
            setRedirected(true)
        }
    }
    const isCult =
        window.location.host === "cult-staging.app.rep3.gg" ||
        window.location.host === "cult.rep3.gg"

    if (isCult) {
        return (
            <ErrorBoundary>
                <Suspense fallback={<div></div>}>
                    <div className="App">
                        <div className="App-header">
                            <CultDao />
                        </div>
                    </div>
                </Suspense>
            </ErrorBoundary>
        )
    }

    return (
        <ErrorBoundary>
            <Suspense fallback={<div></div>}>
                <div className="App">
                    <div className="App-header">
                        <MainTool />
                    </div>
                </div>
            </Suspense>
        </ErrorBoundary>
    )
}

export default App
